import React from 'react'
const CampaignIcon = React.forwardRef((props, ref) => (
  <svg
    {...props}
    ref={ref}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.95833 14C3.95833 13.526 4.10677 13.1367 4.40365 12.832C4.70052 12.5273 5.07986 12.375 5.54167 12.375H13.4583C13.9201 12.375 14.2995 12.5273 14.5964 12.832C14.8932 13.1367 15.0417 13.526 15.0417 14V17.25C15.0417 17.724 14.8932 18.1133 14.5964 18.418C14.2995 18.7227 13.9201 18.875 13.4583 18.875H5.54167C5.07986 18.875 4.70052 18.7227 4.40365 18.418C4.10677 18.1133 3.95833 17.724 3.95833 17.25V14ZM18.0599 5.72266C18.6866 6.36589 19 7.14453 19 8.05859V23.75C18.967 24.6641 18.6536 25.4258 18.0599 26.0352C17.4661 26.6445 16.724 26.9661 15.8333 27H3.16667C2.27604 26.9661 1.53385 26.6445 0.940104 26.0352C0.346354 25.4258 0.0329861 24.6641 0 23.75V4.25C0.0329861 3.33594 0.346354 2.57422 0.940104 1.96484C1.53385 1.35547 2.27604 1.03385 3.16667 1H12.1224C13.013 1 13.7717 1.32161 14.3984 1.96484L18.0599 5.72266ZM16.625 23.75H16.5755V9.125H12.6667C12.2049 9.125 11.8255 8.97266 11.5286 8.66797C11.2318 8.36328 11.0833 7.97396 11.0833 7.5V3.4375H3.16667C2.67188 3.50521 2.40799 3.77604 2.375 4.25V23.75C2.40799 24.2578 2.67188 24.5286 3.16667 24.5625H15.8333C16.3281 24.5286 16.592 24.2578 16.625 23.75ZM4.75 7.5C4.25521 7.46615 3.99132 7.19531 3.95833 6.6875C3.99132 6.17969 4.25521 5.90885 4.75 5.875H8.70833C9.20312 5.90885 9.46701 6.17969 9.5 6.6875C9.46701 7.19531 9.20312 7.46615 8.70833 7.5H4.75ZM4.75 10.75C4.25521 10.7161 3.99132 10.4453 3.95833 9.9375C3.99132 9.42969 4.25521 9.15885 4.75 9.125H8.70833C9.20312 9.15885 9.46701 9.42969 9.5 9.9375C9.46701 10.4453 9.20312 10.7161 8.70833 10.75H4.75ZM14.25 20.5C14.7448 20.5339 15.0087 20.8047 15.0417 21.3125C15.0087 21.8203 14.7448 22.0911 14.25 22.125H10.2917C9.79688 22.0911 9.53299 21.8203 9.5 21.3125C9.53299 20.8047 9.79688 20.5339 10.2917 20.5H14.25Z"
      fill="white"
    />
    <path
      d="M21.2793 1.1046L21.2793 1.10461C20.0545 1.58335 19.0279 2.59682 18.544 3.95977C18.0135 5.45363 18.2536 7.03382 19.0676 8.22336L13.0618 25.1372C12.5778 26.5005 13.228 28.0215 14.5806 28.5017C15.9335 28.9821 17.3972 28.2115 17.8812 26.8484L23.8869 9.93458C25.2687 9.52469 26.4517 8.44968 26.9821 6.95596C27.466 5.59315 27.3083 4.15902 26.6599 3.01519L26.5679 2.85298L26.5341 2.84097C26.0891 2.35433 25.2864 2.57437 25.0723 3.17744L24.4263 4.99668C24.3489 5.21469 24.138 5.29315 23.9865 5.23936L21.9511 4.51662C21.7996 4.46283 21.6855 4.26898 21.7629 4.05096L22.4089 2.23173C22.6452 1.56626 22.0311 0.810761 21.2793 1.1046ZM15.2625 26.5813C15.1021 26.5244 14.9834 26.3204 15.0642 26.0929C15.1449 25.8655 15.3657 25.782 15.5261 25.839C15.6865 25.896 15.8052 26.1 15.7245 26.3274C15.6437 26.5548 15.423 26.6383 15.2625 26.5813Z"
      fill="white"
      stroke="#E80C1C"
    />
  </svg>
))

export default CampaignIcon
