import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'

import { App } from '@pages/App'
import GlobalStyles from '@core/styles'
import Provider from '@configs/provider'
import 'rsuite5/DateRangePicker/styles/index.css'
import 'rsuite/dist/styles/rsuite-default.css'
import '@configs/i18n'
import '@assets/styles/index.css'

const createApp = (App) => {
  ReactDOM.render(App, document.getElementById('root'))
}

createApp(
  <Provider>
    <GlobalStyles />
    <App />
  </Provider>
)
