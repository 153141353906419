import { Table as BaseTable } from 'rsuite'
import styled from '@emotion/styled'

export const Table = styled(BaseTable)`
  .rs-table-row-header .rs-table-cell,
  .rs-table-hover .rs-table-row-header:hover .rs-table-cell-group,
  .rs-table-hover .rs-table-row-header:hover .rs-table-cell {
    background: #f3f4f6 !important;
  }

  .rs-table-row-header,
  .rs-table-row-header .rs-table-cell,
  .rs-table-row-header .rs-table-cell-content {
    height: 53px !important;
    line-height: 17px;
    color: black;
    font-weight: 600;
    font-size: 1rem;
  }

  .rs-table-row-header .rs-table-cell-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rs-table-cell-wrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .rs-checkbox-wrapper {
    background: white;
  }
`

export const Pagination = styled(BaseTable.Pagination)`
  .rs-pagination-btn-active a,
  .rs-table-pagination-end :not(.rs-pagination-btn-disabled) .rs-icon {
    color: black !important;
    font-weight: 600;
  }

  .rs-pagination-btn a {
    font-size: 0.85rem;
  }

  .rs-pagination-btn-active a {
    font-weight: bold;
  }

  .rs-table-pagination-end {
    display: flex;
    align-items: flex-start;

    ::before {
      content: '${({ summary }) => summary}';
      margin-right: 1.5rem;
      font-size: 0.85rem;
      font-weight: 500;
      font-family: var(--font-primary);
      display: inline-block;
      color: black;
    }
  }
`
