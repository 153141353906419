import request from './request'

export default (() => ({
  table({ total, page, ...params }) {
    return request.common(
      'get',
      'report/search'
    )({
      total,
      page,
      ...params
    })
  },
  campaignTable({ total, page, ...params }) {
    return request.common(
      'get',
      'report/search/campaign'
    )({
      total,
      page,
      ...params
    })
  },
  language: {
    filter({ ...params }) {
      return request.common(
        'get',
        'languageMaster'
      )({
        ...params
      })
    },
    search({ type, payloadId, group }) {
      return request.common(
        'get',
        `languageMaster/detail?type=${type}&templateId=${payloadId}&group=${group}`
      )()
    },
    save(data) {
      return request.common('patch', 'languageMaster')(data)
    }
  },
  filters: {
    all() {
      return request.common('get', 'getSearchField')()
    },
    dealerCodes(countryCode) {
      return request.common(
        'get',
        'getSearchField/dealerCodes'
      )({ countryCode })
    },
    branchCodes(dealerCode) {
      return request.common('get', 'getSearchField/branchCodes')({ dealerCode })
    },
    ciNo(campaignType) {
      return request.common('get', 'getSearchField/ciNo')({ campaignType })
    }
  },
  reports: {
    attachment(list) {
      return request.common('get', 'report/resource')({ ids: list })
    },
    csv(data) {
      return request.common(
        'post',
        'report/list'
      )({ params: { ids: data.ids } })
    }
  },
  campaignReports: {
    attachment(list) {
      return request.common('get', 'report/campaign/resource')({ ids: list })
    },
    csv(data) {
      return request.common(
        'post',
        'report/campaign/list'
      )({ params: { ids: data.ids } })
    }
  },
  column: {
    getColumnConfig() {
      return request.common('get', 'columnConfig')()
    },
    updateColumnConfig(data) {
      return request.common('put', 'columnConfig')({ columnConfigs: data })
    }
  },
  campaignColumn: {
    getColumnConfig() {
      return request.common('get', 'columnConfig/campaign')()
    },
    updateColumnConfig(data) {
      return request.common(
        'put',
        'columnConfig'
      )({ columnConfigs: data, type: 'campaign' })
    }
  },
  transaction: {
    delete(transactions) {
      return request.common(
        'post',
        'transaction/delete'
      )({ transactions: transactions })
    },
    getByIdAndType(data) {
      const method = data.method ? `&method=${data.method}` : ''
      return request.common(
        'get',
        `evidenceInformation/${data.id}?type=${data.type}${method}`
      )()
    }
  },
  campaignTransaction: {
    delete(transactions) {
      return request.common(
        'post',
        'transaction/campaign/delete'
      )({ transactions: transactions })
    }
  },
  submission: {
    comment: {
      get({ id, part, side, method }) {
        return request.common(
          'get',
          `submission/technicalDesignComment?submissionId=${id}&part=${part}${
            side ? `&side=${side}` : ''
          }${method ? `&method=${method}` : ''}`
        )()
      },
      patch({
        submissionId,
        part,
        side,
        technicalDesignGroup,
        technicalDesignComment,
        method
      }) {
        return request.common(
          'patch',
          `submission/technicalDesignComment`
        )(
          side
            ? {
                submissionId,
                part,
                side,
                technicalDesignGroup,
                technicalDesignComment,
                method
              }
            : {
                submissionId,
                part,
                technicalDesignGroup,
                technicalDesignComment
              }
        )
      },
      delete(id) {
        return request.common(
          'delete',
          `submission/technicalDesignComment?commentId=${id}`
        )()
      }
    },
    judgement: {
      get({ id, part, side, method }) {
        return request.common(
          'get',
          `submission/judgementDesign?submissionId=${id}&part=${part}${
            side ? `&side=${side}` : ''
          }${method ? `&method=${method}` : ''}`
        )()
      },
      patch({ submissionId, part, side, judgementDesign, method }) {
        return request.common(
          'patch',
          'submission/judgementDesign'
        )(
          side
            ? {
                submissionId,
                part,
                side,
                judgementDesign,
                method
              }
            : {
                submissionId,
                part,
                judgementDesign
              }
        )
      }
    },
    technicalDesignGroup: {
      get() {
        return request.common(
          'get',
          'submission/technicalDesignComment/master'
        )()
      }
    }
  },
  photo: {
    get({ diagnosisId, vinNo }) {
      return request.common(
        'get',
        `diagnosis/photo/configuration?diagnosisId=${diagnosisId}&vinNo=${vinNo}`
      )()
    }
  },
  seat: {
    get({ area }) {
      return request.common(
        'get',
        `diagnosis/seat-wrinkle/configuration?area=${area}&language=en`
      )()
    }
  },
  dashboard: {
    import: {
      excel: {
        warranty: {
          post(params) {
            return request.common(
              'post',
              'dashboard/import/excel/warranty'
            )(params)
          }
        }
      }
    },
    warranty: {
      utilizationRatio: {
        get(params) {
          return request.common(
            'get',
            'dashboard/warranty/utilization-ratio'
          )(params)
        },
        downloadExcel: {
          get(params) {
            return request.common(
              'get',
              'dashboard/warranty/utilization-ratio/download-excel'
            )(params)
          }
        }
      },
      utilizationRatioTop10: {
        get(params) {
          return request.common(
            'get',
            'dashboard/warranty/utilization-ratio-dealer-top10'
          )(params)
        }
      },
      accuracyByPart: {
        get(params) {
          return request.common(
            'get',
            'dashboard/warranty/accuracy-by-part'
          )(params)
        }
      },
      benefit: {
        get(params) {
          return request.common('get', 'dashboard/warranty/benefit')(params)
        }
      },
      submissionConcern: {
        get(params) {
          return request.common(
            'get',
            'dashboard/warranty/submission-concern'
          )(params)
        }
      },
      claim: {
        get(params) {
          return request.common('get', 'dashboard/warranty/claim')(params)
        },
        downloadExcel: {
          get(params) {
            return request.common(
              'get',
              'dashboard/warranty/claim/download-excel'
            )(params)
          }
        }
      }
    }
  }
}))()
