import React from 'react'

export default ({
  width = '38.555',
  height = '41.637',
  stroke = '#fff',
  fill = '#eb0a1e'
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 38.555 41.637"
    >
      <defs>
        <filter
          id="a"
          x="0"
          y="0"
          width={width}
          height={height}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="0.5" result="b" />
          <feFlood floodOpacity="0.129" />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g filter={'url(#a)'} transform="matrix(1, 0, 0, 1, 0, 0)">
        <path
          fill={fill}
          stroke={stroke}
          d="M13.5,31H3.875A3.88,3.88,0,0,1,0,27.125V3.875A3.88,3.88,0,0,1,3.875,0H13.5A15.5,15.5,0,0,1,24.46,26.46,15.4,15.4,0,0,1,13.5,31ZM8.191,10.82h0L15.187,17.9l-.42.426a.7.7,0,0,0,0,.986l6.453,6.534a2.171,2.171,0,0,0,3.1,0,2.241,2.241,0,0,0,0-3.137l-6.454-6.533a.682.682,0,0,0-.974,0l-.393.4L9.5,9.493l.327-.331A.7.7,0,0,0,9.75,8.107L6.895,5.98a.675.675,0,0,0-.408-.136.682.682,0,0,0-.488.2L4.788,7.275a.7.7,0,0,0-.067.907l2.1,2.889a.684.684,0,0,0,1.042.08l.326-.33Zm3.6,5.232-6.578,6.66a2.239,2.239,0,0,0,0,3.137,2.171,2.171,0,0,0,3.1,0l5.671-5.742a1.853,1.853,0,0,1-.31-2.15Zm5.59-1.2a1.778,1.778,0,0,1,1.27.531l.731-.741a5.106,5.106,0,0,0,5.35-1.234,5.273,5.273,0,0,0,1.442-4.526.473.473,0,0,0-.319-.376.465.465,0,0,0-.476.116l-1.085,1.1a2.244,2.244,0,0,1-3.2,0,2.314,2.314,0,0,1,0-3.242l1.085-1.1a.476.476,0,0,0,.115-.482.469.469,0,0,0-.372-.323A5.11,5.11,0,0,0,17.443,6.03a5.282,5.282,0,0,0-1.188,5.5l-1.586,1.606,1.889,1.913A1.787,1.787,0,0,1,17.376,14.848Z"
          transform="translate(8 0.59) rotate(11)"
        />
      </g>
    </svg>
  )
}
