import React from 'react'
import { FormControl, FormGroup, Icon, Tooltip, Whisper } from 'rsuite'
import { Label } from './Form.style'
import classnames from 'classnames'

const Field = ({
  className,
  name,
  label,
  accepter,
  render,
  error,
  style,
  wrapperStyle,
  required,
  tooltip,
  ...props
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <FormGroup
        className={classnames(error ? 'has-error' : '', className)}
        style={wrapperStyle}
      >
        <Label data-required={required}>
          {label}
          {tooltip && (
            <Whisper
              trigger="hover"
              placement="right"
              speaker={<Tooltip>{tooltip}</Tooltip>}
            >
              <Icon icon="info" />
            </Whisper>
          )}
        </Label>
        {React.isValidElement(render) ? (
          render
        ) : render ? (
          React.createElement(render, { name, errorMessage: error, ...props })
        ) : (
          <FormControl
            name={name}
            accepter={accepter}
            errorMessage={error}
            style={style}
            {...props}
          />
        )}
      </FormGroup>
    </div>
  )
}

export default Field
