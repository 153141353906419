import React from 'react'
import { StyledForm } from './Form.style'
import { noop } from 'lodash'

const Form = React.forwardRef(
  (
    {
      model,
      initialValues = {},
      onChange = noop,
      onSubmit = noop,
      children,
      isCampaign,
      ...props
    },
    ref
  ) => {
    const [rendered, reRender] = useToggle()
    const [formDefaultValue, setFormDefaultValue] =
      React.useState(initialValues)
    const [formValue, setFormValue] = React.useState(initialValues)
    const [formError, setFormError] = React.useState({})
    const formRef = React.useRef()

    React.useImperativeHandle(ref, function () {
      return {
        ...formRef.current,
        submit: () => {
          formRef.current.check() && onSubmit(formValue)
        },
        initialize: (values) => {
          setFormDefaultValue(values)
        },
        setErrors: setFormError,
        setValues: (obj) => setFormValue({ ...obj }),
        setValue: (name, value = '') => {
          setFormValue((values) => ({ ...values, [name]: value }))
        },
        setError: (name, value) => {
          setFormError((values) => ({ ...values, [name]: value }))
        },
        resetErrors: () => {
          setFormError({})
        },
        resetValues: () => {
          setFormValue(() => {
            const result = Object.keys(formDefaultValue).reduce(
              (a, c) => ({ ...a, [c]: formDefaultValue[c] || '' }),
              {}
            )
            return result
          })

          reRender()
        },
        reRender
      }
    })

    return (
      <StyledForm
        {...props}
        campaign={isCampaign ? '100%' : 'auto'}
        model={model}
        ref={formRef}
        formDefaultValue={formDefaultValue}
        formError={formError}
        formValue={formValue}
        onChange={(fV) => {
          onChange(fV)
          setFormValue(fV)
        }}
        onCheck={setFormError}
        onError={setFormError}
      >
        {rendered && children}
      </StyledForm>
    )
  }
)

const useToggle = () => {
  const [rendered, setRendered] = React.useState(true)

  React.useEffect(() => {
    !rendered && setRendered(!rendered)
  }, [rendered])

  const reRender = () => {
    setRendered(false)
  }

  return [rendered, reRender]
}

Form.Field = require('./Field').default
export default Form
