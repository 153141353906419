export const PATH = {
  HOME: '/',
  SEARCH: '/search',
  LANGUAGE: '/languages',
  CAMPAIGN: '/campaign',
  PREVIEW_IMAGE: '/preview-image',
  DASHBOARD: '/dashboard'
}

export const FORMAT = {
  FE2BE: 'YYYY-MM-DD',
  BE2FE: 'DD/MM/YYYY HH:mm:ss',
  STATUS: 'DD MMM YYYY HH:mm a'
}

export const LIMIT_PER_PAGE = 20

export const ACCOUNT_ROLE = {
  DISTRIBUTOR: 'distributor',
  DEALER: 'dealer'
}

export const ISO_LANGUAGE_CODE = {
  ch: 'zh-cn',
  en: 'en-gb',
  th: 'th',
  id: 'id',
  vi: 'vi'
}

export const ROLE = {
  ADMIN: 'admin',
  DEALER: 'dealer',
  TDEM: 'tmap-em'
}
