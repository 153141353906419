import { combineProviders } from 'react-combine-providers'

// app providers
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import { StoreProvider } from '@core/contexts/store'

// provider props
import { theme } from '@core/styles'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

const providers = combineProviders()

providers.push(Router)
providers.push(ThemeProvider, { theme })
providers.push(StoreProvider)
providers.push(QueryClientProvider, { client: queryClient })

export default providers.master()
