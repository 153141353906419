import React from 'react'
import load from 'react-loadable'
import { Loader } from 'rsuite'
import { PATH } from './constants'

const loadable = (loader) =>
  load({
    loader,
    loading: () => (
      <div className="screen center">
        <Loader />
      </div>
    )
  })

const routes = [
  {
    component: loadable(() => import('@pages/LanguagesPage/LanguagesPage')),
    path: PATH.LANGUAGE
  },
  {
    component: loadable(() => import('@pages/SearchPage/SearchPage')),
    path: PATH.SEARCH
  },
  {
    path: PATH.HOME,
    component: loadable(() => import('@pages/Home/Home'))
  },
  {
    path: PATH.PREVIEW_IMAGE,
    component: loadable(() =>
      import('@pages/PreviewImagePage/PreviewImagePage')
    )
  },
  {
    path: PATH.CAMPAIGN,
    component: loadable(() => import('@pages/SearchPage/SearchPage')),
    props: {
      isCampaign: true
    }
  },
  {
    path: PATH.DASHBOARD,
    component: loadable(() => import('@pages/DashboardPage/DashboardPage'))
  },
  {
    exact: false,
    component: loadable(() => import('@pages/NotFound/Home'))
  }
]

export default routes
